<template>
    <keep-alive v-if="responsiveStyles.styles && imagedata[fieldname]">

            <keep-alive v-if="sourceToRender.length===0">
                <img :class="[imgclass]" 
                    :src="responsiveStyles.image_original"
                    :alt="responsiveStyles.alt"
                    :width="responsiveStyles.width" :height="responsiveStyles.height"
                    :importance="priority">
            </keep-alive>

            <keep-alive v-else>
                <picture :class="[imgclass]">
                    <source v-for="(el, i) in this.sourceToRender.reverse()" :key="'source'+i"
                        :srcset="responsiveStyles.styles['_x1'][breakstyles[el]].url + ' 1x, ' + 
                                responsiveStyles.styles['_x2'][breakstyles[el]].url + ' 2x'"
                        :media="'(min-width: ' + el + 'px)'"
                        :alt="responsiveStyles.alt"
                        :importance="priority"
                    >

                    <img class="dynamic"
                        :src="responsiveStyles.styles['_x1'][breakstyles[sourceToRender[0]]].url + ' 1x, ' + 
                            responsiveStyles.styles['_x2'][breakstyles[sourceToRender[0]]].url + ' 2x'"
                        :alt="responsiveStyles.alt"
                        :importance="priority"
                    >

                    <figcaption v-if="showcaption">
                        {{ responsiveStyles.alt }}
                    </figcaption>
                    <!-- :width="responsiveStyles.width" :height="responsiveStyles.height" -->
                </picture>
            </keep-alive>

    </keep-alive>
</template>

<script>
export default {
    name: 'images',
    data: () => {
        return {
            responsiveStyles: {}
        }
    },
    props: {
        imagedata: Object,
        showcaption: Boolean,
        breakstyles: Object,
        imgclass: String,
        fieldname: {
            type: String,
            default: 'field_multipla'
        },
        resultindex: {
            type: Number,
            default: 0
        },
        priority: {
            type: String,
            default: "low"
        }
    },
    computed: {
        sourceToRender() {
            if(this.breakstyles)
                return Object.keys(this.breakstyles)
            else 
                return []
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        imageLang() {
            return this.lang==this.defaultLang ? '' : (this.lang+'/')
        }
    },
    mounted(){
        if(this.imagedata.attributes) {
            fetch(process.env.VUE_APP_ENDPOINT + 
                this.imageLang + 
                'images-url/' + 
                this.imagedata.attributes.drupal_internal__nid + '/' + this.fieldname )
            .then(res => res.json())
            .then(json => {
                if(json.images) {
                    this.responsiveStyles = json.images[this.resultindex]
                }
            })
        }
    },
    watch: {
        imagedata() {
            fetch(process.env.VUE_APP_ENDPOINT +
                this.imageLang + 
                'images-url/' +    
                this.imagedata.attributes.drupal_internal__nid + '/' + this.fieldname )
            .then(res => res.json())
            .then(json => {
                if(json.images) {
                    this.responsiveStyles = json.images[this.resultindex]
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    width: 100%;
}

.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
}
</style>