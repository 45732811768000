<template>
  <div class="main-container il-progetto">
    <header-intro :pagedata="pageData"></header-intro>
    <div class="main-content" v-if="this.pageData && this.creditsData">
      <div class="container" v-if="this.testiPagina.length>0">
        <div class="content">

          <div v-if="this.testiPagina[0]">
            <h2 v-if="this.testiPagina[0].attributes.field_ui_text_titolo"
              v-html="this.testiPagina[0].attributes.field_ui_text_titolo.value "></h2>
            <div v-if="this.testiPagina[0].attributes.field_text_with_summary"
              v-html="this.testiPagina[0].attributes.field_text_with_summary.summary"></div>
            <div v-if="this.testiPagina[0].attributes.field_text_with_summary"
              v-html="this.testiPagina[0].attributes.field_text_with_summary.value"></div>
          </div>
          
          <div>
            <!--<img class="image-col" src="../assets/flyer-totem-outlined.jpg" width="auto" height="300" alt="">-->
            <figure>
              <images :imagedata="pageData" fieldname="field_page_immagini" :resultindex="0"
                :breakstyles="{'0': 'md_image', '768': 'lg_image'}"></images>
            </figure>

            <!--<div v-if="this.testiPagina[1] && this.testiPagina[1].attributes">
              <h2 v-if="this.testiPagina[1].attributes.field_ui_text_titolo"
                v-html="this.testiPagina[1].attributes.field_ui_text_titolo.value"></h2>
              <div v-if="this.testiPagina[1].attributes.field_text_with_summary"
                v-html="this.testiPagina[1].attributes.field_text_with_summary.summary"></div>
              <div v-if="this.testiPagina[1].attributes.field_text_with_summary"
                v-html="this.testiPagina[1].attributes.field_text_with_summary.value"></div>
            </div>-->
          </div>

        </div>

        <router-link class="aderisci-btn btn icon-left"
          :to="{ path: this.$store.state.labelData[this.lang].contatti.contattilink/*, hash: '#aderisci'*/ }">
          {{ this.$store.state.labelData[this.lang].aderisci_button }}
        </router-link>

        <div class="logos">
          <div class="content">
            <h2>{{ this.$store.state.labelData[this.lang].credits.main.toUpperCase() }}</h2>
            <div class="logo_grid first-row">
              <figure>
                <images :imagedata="creditsData" fieldname="field_page_immagini" :resultindex="2"
                  :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                <!--<figcaption>Camera di Commercio Venezia Giulia Trieste Gorizia - Gestione Fondo Gorizia</figcaption>-->
              </figure>
              <figure>  
                <images :imagedata="creditsData" fieldname="field_page_immagini" :resultindex="4"
                  :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                <!--<figcaption>Ente turismo FVG</figcaption>-->
              </figure>
              <figure>
                <images :imagedata="creditsData" fieldname="field_page_immagini" :resultindex="5"
                  :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                <!--<figcaption>Ente turismo FVG</figcaption>-->
              </figure>
            </div>
          </div>

          <div class="content">
            <h2>{{ this.$store.state.labelData[this.lang].credits.in_collab.toUpperCase() }}</h2>
            <div class="logo_grid">
              <figure>
                <images :imagedata="creditsData" fieldname="field_page_immagini" :resultindex="0"
                  :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                <!--<figcaption>Soprintendenza Archeologica Belle Arti e Paesaggio del Friuli Venezia GIulia</figcaption>-->
              </figure>
              <figure>
                <images :imagedata="creditsData" fieldname="field_page_immagini" :resultindex="1"
                  :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                <!--<figcaption>Università degli Studi di Trieste Dipartimento di Studi Umanistici</figcaption>-->
              </figure>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
  import HeaderIntro from '../components/HeaderIntro'
  import Images from '../components/Images'
  import {
    fetchSinglePage,
    getTranslation
  } from '../libs/drupalClient'

  export default {
    name: 'il-progetto',
    components: {
      HeaderIntro,
      Images
    },
    data() {
      return {
        pageData: null,
        creditsData: null,
        currentPageID: "a2354701-0fc8-4a9a-86a6-228e1ccbfedf",
        creditsPageID: "7c47db39-986e-4c59-bb1d-8b7f25ed3431"
      }
    },
    metaInfo() {
      return {
        title: this.pagemetatitle,
        meta: [
          {
            property: 'og:title',
            content: this.pagemetatitle
          }
        ]
      }
    },
    computed: {
      pagemetatitle() {
        if(this.lang == 'it') return 'Il Progetto'
        else if(this.lang=='de') return 'Das Projekt'
        else return 'The Project'
      },
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      testiPagina() {
        return this.pageData.field_ref_ui_text.filter(f => f.attributes.field_tipo == 'Testo pagina')
      }
    },
    mounted() {
      fetchSinglePage(this.currentPageID, this.lang)
      .then(res => this.pageData = res )

      fetchSinglePage(this.creditsPageID, this.lang)
      .then(res => this.creditsData = res )
    },
    watch: {
      lang() {
        getTranslation(this.$route.name, this.lang)
        .then(res => this.$router.push({ path: res }))

        fetchSinglePage(this.currentPageID, this.lang)
        .then(res => this.pageData = res)
        
        fetchSinglePage(this.creditsPageID, this.lang)
        .then(res => this.creditsData = res)
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.image-col { display: none; }

.aderisci-btn {
  margin: 3rem 0;
  background-color: #181923;

  &:hover {
    background-color: rgba(#3A3C4D, .2);
    transition: all 200ms ease;
    color: #fff;
  }
}

figure {
  margin: 0;
  img { width: 100%; }
}

figcaption {
  font-size: 0.875rem;
  color: #8E8E8F;
}

.btn {
  text-decoration: none;
  text-transform: uppercase;
}

.logos {
  width: 100%;
  margin-bottom: 3rem;
  
  .content {
    h2 {
      margin-top: 1rem;
      font-size: 1.25rem;
      line-height: 1.625rem;
      color: #C3923F;
    }
    div { width: 100%; }
  }

  p span {
    margin-bottom: .5em;
    display: block;
  }
  
  .logo_grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: column;
    margin: 1rem 0rem;

    &>div,
    figure {
      width: 100%;

      picture {
        width: 100%;
        display: flex;
        height: 100%;
        align-items: flex-end;

        ::v-deep img {
          width: 100%;
          height: 100%;
          max-height: 80px;
          object-fit: contain;
          margin-bottom: 10px;
        }
      }
    }
  }

  figure {
    margin: .5em 0;
    img { width: 100%; }
  }
}


@media (min-width: 768px) and (max-width: 992px) {

.logos {
    .content:first-child div:nth-child(2) {
      figure picture {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      figure:nth-child(2) {
        width: 280px;
      }

      figure:last-child {
        width: 120px;
      }

  }
}
}

@media (min-width: 480px) {
  .logos .logo_grid figure {
    picture {
      max-width: 350px;
      margin: 0px auto 0px 0px;
      height: 100px;

      ::v-deep img {
        width: auto;
        max-width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .logos {
    .logo_grid {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &>div, figure { width: 44%; }
    }

    h2 { width: 80%; }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .content h2 { margin-top: 1rem; }
  }
}

@media (min-width: 992px) {
  h2 { margin-top: 0; }

  .content {
    display: flex;
    justify-content: space-between;
    div:first-child { width: 55%; }
    div:nth-child(2) { width: 40%; }
  }


  .logos {

    h2 { width: 75%; }
    .content div:nth-child(2) { width: 55%; }
    
    .content:first-child div:nth-child(2) {
      width: 75%;

      figure:nth-child(2) {
        width: 280px;
      }
      figure:last-child {
        width: 120px;
      }
    }
    
    ::v-deep img {
      max-width: 240px;
      max-height: 100px;
      width: auto;
      height: auto;
    }
  }
}

@media (min-width: 1024px) {
  .image-col { display: block; }

  .il-progetto .main-content {
    position: relative;
    &::after {
      content: "";
      width: 600px;
      height: 500px;
      background-image: url("../assets/il-progetto-deco.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;
      bottom: 0;
      right: 0;
      z-index: 8;
      position: absolute;
    }
  }

  /* ::v-deep p {
    line-height: 1.5rem;
    font-size: 1rem;
  } */

  .logos {
    margin-bottom: 0;
    padding-bottom: 4rem;

    .logo_grid { margin: 0; }

    h2 { width: 100%; }

    ::v-deep picture {
      width: 450px;
      height: 100px;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        max-width: 100%;
        max-height: none;
        bottom: 0;
        height: 100px;
        width: auto;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }
  }
}

@media (min-width: 1200px) {
  .il-progetto .main-content::after { width: 1000px; }
  .logos { padding-bottom: 10rem; }
}

@media (min-width: 1500px) {
  .logos { padding-bottom: 6rem; }
}
</style>